<div class="create__content-wrapper">
    <div class="header-with-tabs">
        <div class="create__header-wrapper create__header-wrapper--column">
            <!-- <div class="header-wrapper--with-total">
                <h1 class="text-primary" *ngIf="userCanUpdate || !technicianId"> {{!technicianId ?
                    'Agregar Nuevo Técnico' : 'Editar Técnico'}} </h1>
                <h1 class="text-primary" *ngIf="!userCanUpdate && technicianId"> Detalle del Técnico </h1>
                <h1> {{'Total ganancias: $' }}  </h1>
            </div> -->
            <h1 class="text-primary" *ngIf="userCanUpdate || !technicianId"> {{!technicianId ?
                'Agregar Nuevo Técnico' : 'Editar Técnico'}} </h1>
            <h1 class="text-primary" *ngIf="!userCanUpdate && technicianId"> Detalle del Técnico </h1>
            <h4 *ngIf="technicianId"> {{technicianValues.name}}</h4>
        </div>
        <div class="tab-menu-wrapper" *ngIf="technicianId">
            <div class="tab-option" *ngFor="let tabOption of tabMenuOptions"
                [ngClass]="{'selected': tabSelected === tabOption.key}"
                (click)="tabSelected = tabOption.key; tabOption.key !== 'INFORMATION' ? cleanSearch(tabOption.key) : ''; 
            tabOption.key === 'CLIENTS' ? fillClientsTable() : tabOption.key === 'QUOTATIONS' ? fillQuotationsTable() : ''">
                <span>{{tabOption.label}}</span>
            </div>
        </div>
    </div>

    <div class="create__body-wrapper create__body-wrapper--no-center no-overflow-bar" *ngIf="tabSelected === 'INFORMATION'">
        <div class="create__img-wrapper">
            <div class="img-box">
                <img [src]="technicianId && technicianValues.picUrl ? technicianValues.picUrl : technicianFormData.pic ? temporarySelectedImg : '../../../assets/icons/user-icon.svg'" alt="profile image">
            </div>
            <div class="add-icon">
                <img *ngIf="!technicianValues.picUrl && !technicianFormData.pic" src="../../../assets/icons/add-icon.svg" alt="add">
                <img *ngIf="technicianValues.picUrl || technicianFormData.pic" src="../../../assets/icons/edit.svg" alt="edit">
                <input type="file" (change)="attachImg($event)" accept=".png, .jpg">
            </div>
        </div>
        <div class="create__form-wrapper">
            <div class="create__form-box">
                <prt-form #formTemplate [fields]="technicianForm" (change)="createTechnicianFormChanges($event)"
                    (isFormValid)="createTechnicianFormISValid($event)"></prt-form>
            </div>
        </div>
    </div>

    <div class="create__body-wrapper create__body-wrapper--block create__body-wrapper--with-margin-top"
        *ngIf="tabSelected !== 'INFORMATION'">
        <div class="search-wrapper">
            <div class="search-form-wrapper">
                <prt-form #searchFormTemplate [fields]="searchForm" (change)="searchFormChanges($event)"></prt-form>
            </div>
            <!-- <h2>{{'Total de ganancias' </h2> -->
        </div>

        <div class="table-external-wrapper" *ngIf="tabSelected === 'CLIENTS'">
            <prt-table [rows]="clientsTable" [columns]="clientsTableColumns" [icons]="clientsTableIcons"
                [actions]="clientsTableActions" [pagination]="clientsTablePagination"
                (changeCurrentPage)="handleClientsChangePage($event)" [resultsLabel]="'Clientes'"
                [isLoading]="!isClientFetchingData" [showLoading]=true [isExporting]="isExportingClient"
                (onSorting)="onSortClientsTable($event)" columnMode="force">
            </prt-table>
        </div>

        <div class="table-external-wrapper" *ngIf="tabSelected === 'QUOTATIONS'">
            <prt-table [rows]="quotationsTable" [columns]="quotationsTableColumns" [icons]="quotationsTableIcons"
                [actions]="quotationsTableActions" [pagination]="quotationsTablePagination"
                (changeCurrentPage)="handleQuotationChangePage($event)" [resultsLabel]="'Cotizaciones'"
                [isLoading]="!isQuotationFetchingData" [showLoading]=true [isExporting]="isExportingQuotations"
                (onSorting)="onSortQuotationsTable($event)">
            </prt-table>
        </div>
    </div>


    <div class="create__footer-wrapper" *ngIf="userCanUpdate || !technicianId">
        <prt-button type="outline" [callback]="cancelCreation">
            Cancelar
        </prt-button>
        <prt-button type="solid" [callback]="createTechnician">
            Guardar
        </prt-button>
    </div>
    <div class="create__footer-wrapper" *ngIf="!userCanUpdate && technicianId">
        <prt-button type="solid" [callback]="cancelCreation">
            Aceptar
        </prt-button>
    </div>
</div>