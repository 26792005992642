import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { An507FormRefInterface, DropdownField, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { getRole, Role, RoleForm } from 'src/app/models/auth';
import { getError } from 'src/app/models/error';
import { RolesService } from 'src/app/services/roles/roles.service';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Ability, subject } from '@casl/ability';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
  @ViewChild('searchFormTemplate', { static: false }) searchFormId: An507FormRefInterface | undefined;
  @ViewChild('formTemplate', { static: false }) rolesFormId: An507FormRefInterface | undefined;

  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];
  searchQuery: any = {
    page: 1,
    limit: 10,
  };

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;
  isEditing = false;

  translations: Record<string, string> = {
    manage: 'Todos',
    create: 'Crear',
    update: 'Modificar',
    read: 'Ver',
    delete: 'Eliminar',
    subject: 'Funcionalidad',
    subjects: 'Funcionalidades',
    default: 'Desconocido',
    Role: 'Rol',
    User: 'Usuario',
    Client: 'Cliente',
    Product: 'Producto',
    Quotation: 'Cotización',
    Technician: 'Técnico',
  };

  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  rolesTable: any[] = [];

  tableActions: Record<string, unknown> = {
    modifyAction: (row: Record<string, unknown>) => this.editRol(row['id'] as string),
    deleteAction: (row: Record<string, unknown>) => this.deleteRol(row['id'] as string),
  };

  tableColumns = [
    {
      prop: 'name',
      name: 'Nombre',
      maxWidth: 300,
    },
    {
      prop: 'description',
      name: 'Descripción',
      minWidt: 300,
      maxWidth: 500,
      sortable: true,
    },
  ];

  tablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  roleId: string | null = null;
  isFetchingData = false;

  showDeletionModal = false;
  actionsColumns: TableColumn[] = [];
  subjectsRows: Record<string, string | boolean>[] = [];
  actions: string[] = [];
  subjects: string[] = [];
  isExporting = false;

  constructor(
    private rolesService: RolesService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ability: Ability,
    private pdfSvc: CreatedPdfService,
  ) {
    if (ability.can('delete', 'Role')) {
      this.tableColumns.push({
        prop: 'delete',
        name: '',
        minWidt: 50,
        maxWidth: 100,
        sortable: false,
      });
    }
  }

  async ngOnInit(): Promise<void> {
    await this.getPermissions();

    this.route.queryParamMap.subscribe(async params => {
      const sortBy = params.get('sortBy');
      const sortOrder = params.get('sortOrder');
      const page = Number(params.get('page'));

      this.buildQuery(sortBy, sortOrder, page);
      if (page) {
        this.tablePagination['page'] = page;
      }
      this.fillTable(sortBy, sortOrder);
    });

    this.canCreate();
    this.tableActions['downloadAction'] = this.handleCreateReport;
  }

  ngAfterViewInit() {
    this.searchForm[0].triggerOnEnter = this.searchOnEnter;
    this.searchForm[0].triggerOnBlur = this.searchOnBlur;
  }

  buildQuery(sortKey?: string | null, sortOrder?: string | null, page?: number) {
    if (page) {
      Object.assign(this.searchQuery, page);
    }
    if (sortKey) {
      Object.assign(this.searchQuery, { sortKey, sortOrder });
    }
  }

  searchOnEnter = (evt: any) => {
    if (evt.key === 'Enter') {
      const value = evt.target.value;
      this.updateSearchForm(value);
    }
  };

  searchOnBlur = (evt: any) => {
    this.updateSearchForm(evt.target.value);
  };

  updateSearchForm = (value: string) => {
    this.searchFormId?.form.setValue({ [this.searchForm[0].key]: value }, { emitEvent: true });
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsDirty();
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsTouched();
    this.search();
  };

  search = async () => {
    this.isFetchingData = true;
    if (!this.searchQuery['q']) {
      delete this.searchQuery['q'];
    }
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const roles = await this.rolesService.getAllRoles(this.searchQuery);

        this.rolesTable = roles.data.map(rol => {
          return {
            id: rol.id,
            name: rol.name,
            description: rol.description,
            permissions: rol.permissions,
          };
        });

        this.tablePagination = { page: roles.page, limit: roles.limit, count: roles.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  };

  async searchFormChanges(evt: { query: string } | unknown) {
    if ((evt as { query: string }).query) {
      // this.filterFormId?.resetForm();
      this.searchQuery = {
        ...this.searchQuery,
        ['q']: (evt as { query: string }).query,
      };
    } else {
      this.resetSearchQuery();
    }
  }

  resetSearchQuery() {
    delete this.searchQuery['q'];
    this.searchQuery = {
      ...this.searchQuery,
      page: 1,
      limit: 10,
    };
  }

  getPermissions(): Promise<void | string[][]> {
    return Promise.all([this.rolesService.getAllActions(), this.rolesService.getAllSubjects()]).then(res => {
      this.actions = res[0];
      this.subjects = res[1];
    });
  }

  //Roles

  addRol = async () => {
    this.router.navigate(['/private/admin/roles/new']);
  };

  deleteRol(roleId: string) {
    this.roleId = roleId;
    this.showDeletionModal = true;
  }

  cancelRolDeletion = () => {
    this.roleId = null;
    this.showDeletionModal = false;
  };

  confirmDeletion = () => {
    if (this.roleId && this.roleId !== '') {
      this.rolesService
        .deleteRol(this.roleId)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Rol eliminado');
          this.fillTable();
          this.showDeletionModal = false;
          this.roleId = null;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  editRol = async (roleId: string) => {
    this.router.navigate(['/private/admin/roles/edit'], { queryParams: { roleId: roleId } });
  };

  checkDuplicateErrorMessage(title: string, message: string, getBackendErrors?: boolean) {
    let duplicate: any;
    if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  async fillTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const roles = await this.rolesService.getAllRoles({
          page: this.tablePagination.page,
          limit: this.tablePagination.limit,
          sortKey: sortKey ? sortKey : 'name',
          sortOrder: sortOrder ? sortOrder : 'asc',
        });

        this.rolesTable = roles.data.map(rol => {
          return {
            id: rol.id,
            name: rol.name,
            description: rol.description,
            permissions: rol.permissions,
          };
        });

        this.tablePagination = { page: roles.page, limit: roles.limit, count: roles.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  }

  handleChangePage(page: number) {
    this.tablePagination.page = page;
    this.router.navigate(['/private/admin/roles'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  onSortTable(evt: { dir: string; column: string }) {
    this.tablePagination.page = 1;
    this.router.navigate(['/private/admin/roles'], {
      queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
      queryParamsHandling: 'merge',
    });
  }

  checkRoleReadOnlyPermission() {
    return (
      this.ability.can('read', 'Role') && !this.ability.can('update', 'Role') && !this.ability.can('create', 'Role')
    );
  }

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Role');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Role')) {
      this.rolesFormId?.form.disable();
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  handleCreateReport = async () => {
    this.isExporting = true;
    this.searchQuery['limit'] = 0;
    const roles = await this.rolesService.getAllRoles(this.searchQuery);

    const rolesReport = roles.data.map(rol => {
      return {
        name: rol.name,
        description: rol.description,
      };
    });
    const pdfDefinition: any = this.pdfSvc.createdReportRole(rolesReport);
    pdfMake.createPdf(pdfDefinition).download('Roles.pdf');
    // pdfMake.createPdf(pdfDefinition).open();
    this.isExporting = false;
    this.searchQuery['limit'] = 10;
  };
}
