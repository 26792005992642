<div class="content-wrapper">
    <div class="header-wrapper--with-total">
        <div class="header-title-wrapper">
            <h1 class="text-primary"> Cotizaciones </h1>
            <prt-button *ngIf="userCanCreate" color="secondary" [italic]="true" [callback]="addQuotation">
                <div class="button-box">
                    <img class="button-icon" src="../../../assets/icons/add-icon.svg" alt="add">
                    <h5 class="add-label">Agregar</h5>
                </div>
            </prt-button>
        </div>
        <h1> {{'Total: $' + total}}  </h1>
    </div>
    <div class="search-wrapper">
      <div class="search-form-wrapper">
        <prt-form #searchFormTemplate [fields]="searchForm" (change)="searchFormChanges($event)"></prt-form>
      </div>
    </div>
    <div class="table-external-wrapper">
        <prt-table [rows]="quotationsTable" [columns]="tableColumns" [icons]="tableIcons" [actions]="tableActions"
            [pagination]="tablePagination" (changeCurrentPage)="handleChangePage($event)"
            [resultsLabel]="'Cotizaciones'" [isLoading]="!isFetchingData"
            [showLoading]= true
            [isExporting]="isExporting"
            (onSorting)="onSortTable($event)">
        </prt-table>
    </div>

    <prt-modal [displayButton]="false" size="sm" [isActive]="showDeletionModal" (onAction)="onDeleteModalStatus($event)"
    [hasCloseButton]="false">
        <div class="modal-wrapper" *ngIf="canBeDeleted">
            <h2 style="text-align: center" class="text-primary"> Confirmación </h2>
            <span class="paragraph-1">¿Estás seguro que deseas eliminar esta cotización?</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button type="outline" [callback]="cancelDeletion">
                    Cancelar
                </prt-button>
                <prt-button type="solid" [callback]="confirmDeletion">
                    Eliminar
                </prt-button>
            </div>
        </div>

        <div class="modal-wrapper" *ngIf="!canBeDeleted">
            <h2 style="text-align: center" class="text-primary"> Error </h2>
            <span class="paragraph-1">Esta cotización no puede ser eliminada</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button type="outline" [callback]="cancelDeletion">
                    Aceptar
                </prt-button>
            </div>
        </div>
    </prt-modal>
</div>