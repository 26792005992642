<div class="create__content-wrapper">
    <div class="create__header-wrapper">
        <h1 class="text-primary" *ngIf="userCanUpdate || !userId"> {{!userId ?
            'Agregar Nuevo Administrador' : 'Editar Administrador'}} </h1>
        <h1 class="text-primary" *ngIf="!userCanUpdate && userId"> Detalle del Administrador </h1>
    </div>
    <div class="create__body-wrapper">
        <div class="create__form-wrapper">
            <div class="create__form-box">
                <prt-form #formTemplate [fields]="userForm" (change)="createUserFormChanges($event)"
                (isFormValid)="createUserFormISValid($event)"></prt-form>
            </div>
        </div>
    </div>
    <div class="create__footer-wrapper" *ngIf="userCanUpdate || !userId">
        <prt-button type="outline" [callback]="cancelCreation">
            Cancelar
        </prt-button>
        <prt-button type="solid" [callback]="createUser">
            Guardar
        </prt-button>
    </div>
    <div class="create__footer-wrapper" *ngIf="!userCanUpdate && userId">
        <prt-button type="solid" [callback]="cancelCreation">
            Aceptar
        </prt-button>
    </div>
</div>