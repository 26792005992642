import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ability } from '@casl/ability';
import { An507FormRefInterface, BasicFormBase, DropdownField, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { Category } from 'src/app/models/categories';
import { Client } from 'src/app/models/clients';
import { getError } from 'src/app/models/error';
import { Product } from 'src/app/models/products';
import { Quotation, QuotationForm, QuotationProduct, QuotationProductForm } from 'src/app/models/quotation';
import { AuthService } from 'src/app/services';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';
import validation from 'src/app/utils/validation';

@Component({
  selector: 'app-quotations-create',
  templateUrl: './quotations-create.component.html',
  styleUrls: ['./quotations-create.component.scss'],
})
export class QuotationsCreateComponent implements OnInit {
  @ViewChild('formTemplate', { static: false }) quotationsFormId: An507FormRefInterface | undefined;
  @ViewChild('productFormTemplate', { static: false }) productsFormId: An507FormRefInterface | undefined;

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  quotationForm: TextBoxField[] | DropdownField[] = [
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'userId',
      label: 'Técnico',
      width: 'md',
      required: true,
      selectOptions: [],
      validationErrorMessage: 'Técnico inválido',
      showErrorsUx: true,
    }),
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'clientId',
      label: 'Cliente',
      width: 'md',
      required: true,
      selectOptions: [],
      validationErrorMessage: 'Cliente inválido',
      showErrorsUx: true,
    }),
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'status',
      label: 'Estatus',
      width: 'sm',
      required: true,
      selectOptions: [
        {
          label: 'Borrador',
          value: 'CREATED',
        },
        {
          label: 'Enviada',
          value: 'SENT',
        },
        {
          label: 'Pagada',
          value: 'PAID',
        },
        {
          label: 'Cancelada',
          value: 'CANCELED',
        },
      ],
      validationErrorMessage: 'Estatus inválido',
      showErrorsUx: true,
    }),
    {
      controlType: 'textBox',
      key: 'note',
      type: 'text',
      value: '',
      label: 'Nota',
      required: false,
      width: 'xl',
      validationErrorMessage: 'Nota inválida',
      showErrorsUx: true,
    },
  ];

  isQuotationFormValid = false;

  quotationFormData: QuotationForm = {
    userId: '',
    clientId: '',
    note: '',
    products: [],
  };

  isFormLoaded = false;
  quotationId: string | null = null;

  quotationValues: Quotation = {
    createdAt: new Date(),
    id: '',
    note: '',
    clientId: '',
    userId: '',
    products: [
      {
        detail: '',
        name: '',
        productId: '',
        quantity: 0,
        totalPrice: 0,
        unitPrice: 0,
      },
    ],
    updatedAt: new Date(),
    Client: {
      name: '',
      number: 0,
    },
    User: {
      name: '',
    },
    number: 0,
    numberStr: '',
    profits: 0,
    saleOrderId: '',
    status: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    showDetail: false,
  };

  //Products table
  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
  };

  tableActions: Record<string, unknown> = {
    // modifyAction: (row: Record<string, unknown>) => this.editProduct(row),
    deleteAction: (row: Record<string, unknown>) => this.deleteProduct(row['id'] as string),
  };

  productsTable: any[] = [];
  showDeletionModal = false;
  isFetchingData = true;
  productIdToDelete: string | null = null;
  productsSelected: QuotationProductForm[] = [];
  showProductsTable = false;
  actualStatus = 'CREATED';

  tableColumns = [
    {
      prop: 'name',
      name: 'Producto',
      sortable: false,
      maxWidth: 400,
      minWidth: 400,
    },
    {
      prop: 'quantity',
      name: 'Cantidad',
      sortable: false,
      maxWidth: 180,
      minWidth: 180,
    },
    {
      prop: 'productPrice',
      name: 'Precio Compra Socio',
      sortable: false,
      minWidth: 250,
    },
    {
      prop: 'unitPrice',
      name: 'Precio Final Cliente',
      sortable: false,
      minWidth: 250,
    },
    {
      prop: 'totalPrice',
      name: 'Precio Total',
      sortable: false,
      minWidth: 250,
    },
    {
      prop: 'delete',
      name: '',
      sortable: false,
    },
  ];

  tablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  //Product Categories
  productCategories: Category[] = [];
  productCategoriesLoaded = false;

  //Product Addition Modal
  showProductFormModal = false;
  fetchingPrice = false;
  settingTotalPrice = false;

  productForm: TextBoxField[] | DropdownField[] = [
    new BasicFormBase({
      controlType: 'dropdownWithSearch',
      key: 'productId',
      label: 'Producto',
      width: 'xl',
      required: true,
      selectOptions: [],
      validationErrorMessage: 'Técnico inválido',
      showErrorsUx: true,
    }),
    {
      controlType: 'textBox',
      key: 'quantity',
      type: 'number',
      value: '',
      label: 'Cantidad',
      required: true,
      width: 'sm',
      regex: validation.greaterThanZeroPattern,
      validationErrorMessage: 'Cantidad inválida',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'productPrice',
      type: 'text',
      value: '',
      label: 'Precio Compra Socio',
      required: true,
      width: 'sm',
      regex: validation.greaterThanZeroWithDecimals,
      validationErrorMessage: 'Precio inválido',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'unitPrice',
      type: 'text',
      value: '',
      label: 'Precio Final Cliente',
      required: true,
      width: 'sm',
      regex: validation.greaterThanZeroWithDecimals,
      validationErrorMessage: 'Precio inválido',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'totalPrice',
      type: 'text',
      value: '',
      label: 'Precio Total',
      required: false,
      width: 'sm',
    },
  ];

  isProductFormValid = false;

  productFormData: QuotationProductForm = {
    productId: '',
    name: '',
    quantity: 0,
    productPrice: 0,
    unitPrice: 0,
    totalPrice: 0,
  };

  isProductFormLoaded = false;
  productValues: QuotationProduct = {
    productId: '',
    name: '',
    quantity: 0,
    unitPrice: 0,
    totalPrice: 0,
    detail: '',
    product: {
      active: false,
      categoryId: '',
      Category: {
        name: '',
      },
      id: '',
      name: '',
      picUrl: '',
      price: 0,
      sku: '',
      syncId: '',
      unit: '',
      zohoId: '',
    },
  };

  quotationTotalPrice: number | string = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private quotationService: QuotationsService,
    private technicianService: TechniciansService,
    private clientService: ClientsService,
    private productService: ProductsService,
    private productCategoriesService: CategoriesService,
    private authService: AuthService,
    private currencyPipe: CurrencyPipe,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    try {
      const me = await firstValueFrom(this.authService.getMe());

      if (this.technicianService.isTechnician(me.role.name)) {
        this.quotationForm[0].selectOptions = [
          {
            label: me.name || '',
            value: me.id,
          },
        ];
        this.quotationsFormId?.updateForm({
          userId: me.id,
        });
        this.getClients();
      } else {
        this.getTechnicians();
        this.getClients();
      }
    } catch (error) {
      console.error(error);
      this.router.navigate(['/login']);
    }

    this.getProductCategories();

    this.route.queryParamMap.subscribe(async params => {
      this.quotationId = params.get('quotationId');
      if (this.quotationId) {
        this.isFormLoaded = false;
        this.quotationValues = await this.quotationService.getQuotationById(this.quotationId);
        this.quotationTotalPrice = this.quotationValues.total;

        if (this.quotationValues.status === 'SENT') {
          this.quotationForm[2].selectOptions?.splice(0, 1);
        }

        this.actualStatus = this.quotationValues.status;

        setTimeout(() => {
          this.fillForm();
          this.quotationsFormId?.form.controls['userId'].disable();
          this.quotationsFormId?.form.controls['clientId'].disable();
          this.isFormLoaded = true;
          this.canUpdate();
        });

        if (this.quotationValues.products) {
          this.productsSelected = this.quotationValues.products.map((e: any) => e);
          this.fillProductsTable();
        }
      } else {
        this.quotationsFormId?.updateForm({
          status: 'CREATED',
        });
        // this.quotationsFormId?.form.controls['status'].disable();
        this.quotationForm = this.quotationForm.filter(i => i.key != 'status');

        if (localStorage.getItem('quotationData')) {
          this.quotationValues = JSON.parse(localStorage.getItem('quotationData') as string);
          this.fillForm();

          if (this.quotationValues.products) {
            this.productsSelected = this.quotationValues.products.map((e: any) => e);
            this.fillProductsTable();
          }

          localStorage.removeItem('quotationData');
        }
        this.isFormLoaded = true;
      }
    });
  }

  async fillForm() {
    this.quotationsFormId?.updateForm({
      note: this.quotationValues.note,
      clientId: this.quotationValues.clientId,
      userId: this.quotationValues.userId,
      status: this.quotationValues.status,
    });
  }

  // Get technicians
  async getTechnicians() {
    const technicians = await this.technicianService.getAllTechnicians({
      limit: 0,
      sortKey: 'name',
      sortOrder: 'asc',
    });
    this.quotationForm[0].selectOptions = technicians.data.map(technician => {
      return {
        label: technician.name || '',
        value: technician.id,
      };
    });
  }

  // Get clients
  async getClients(technicianId?: string) {
    let clients: any;

    if (technicianId) {
      clients = await this.clientService.getAllClients({
        limit: 0,
        sortKey: 'name',
        sortOrder: 'asc',
      });
    } else {
      clients = await this.clientService.getAllClients({
        limit: 0,
        sortKey: 'name',
        sortOrder: 'asc',
      });
    }
    this.quotationForm[1].selectOptions = clients.data.map((client: Client) => {
      return {
        label: client.name || '',
        value: client.id,
      };
    });
  }

  // Get product categories
  async getProductCategories() {
    const productCategories = await this.productCategoriesService.getAllCategories({
      limit: 0,
      sortKey: 'name',
      sortOrder: 'asc',
    });

    this.productCategories = productCategories.data;
    this.productCategoriesLoaded = true;
  }

  //Permissions

  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Quotation');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Quotation')) {
      this.quotationsFormId?.form.disable();
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Create Quotation
  createQuotation = () => {
    if (!this.quotationFormData.userId || !this.quotationFormData.clientId) {
      return this.checkDuplicateErrorMessage('Formulario inválido', '', true, false, 'QUOTATION');
    }
    if (this.quotationValues?.id && this.isQuotationFormValid) {
      const formData = {
        id: this.quotationValues.id,
        note: this.quotationFormData.note,
        status: this.quotationFormData.status,
        products: this.setProductsOnForm(),
      };
      this.quotationService
        .updateQuotation(formData)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Cotización editada');
          if (formData.status === 'CREATED') {
            this.router.navigate(['/private/quotations/send'], { queryParams: { quotationId: this.quotationId } });
          } else if (formData.status === 'SENT' && (res as any).url) {
            if (navigator.share) {
              navigator
                .share({
                  title: 'Compartir Cotización',
                  text: 'Cotización #' + this.quotationId,
                  url: (res as any).url,
                })
                .then(() => {
                  // this.checkDuplicateSuccessMessage('', 'Cotización enviada exitosamente');
                  this.router.navigate(['/private/quotations']);
                })
                .catch(error => {
                  console.error(error);
                  this.router.navigate(['/private/quotations']);
                });
            }
          } else {
            this.router.navigate(['/private/quotations']);
          }
        })
        .catch(err => {
          this.quotationsFormId?.updateForm({
            status: this.actualStatus,
          });
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error);
          }
        });
    } else if (!this.quotationValues?.id && this.isQuotationFormValid) {
      this.quotationFormData.products = this.setProductsOnForm();
      this.quotationService
        .createQuotation(this.quotationFormData)
        .then((res: any) => {
          this.quotationId = res.id;
          this.checkDuplicateSuccessMessage('', 'Cotización creada');
          this.router.navigate(['/private/quotations/send'], { queryParams: { quotationId: this.quotationId } });
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error);
          }
        });
    } else {
      this.checkDuplicateErrorMessage('Formulario inválido', '', true, false, 'QUOTATION');
    }
  };

  setProductsOnForm() {
    const products = this.productsSelected
      .filter(e => e !== null)
      .map(e => {
        return {
          id: e.productId,
          quantity: this.checkVariableType(e.quantity),
          unitPrice: this.checkVariableType(e.unitPrice),
          totalPrice: e.totalPrice,
        };
      });

    return products;
  }

  createQuotationFormChanges(evt: object) {
    if (!(evt instanceof Event)) {
      Object.assign(this.quotationFormData, { ...(evt as QuotationForm) });
    }
  }

  createQuotationFormISValid(evt: boolean) {
    this.isQuotationFormValid = evt;
  }

  cancelCreation = () => {
    this.router.navigate(['/private/quotations']);
  };

  //Error Messages
  checkDuplicateErrorMessage(
    title: string,
    error: any,
    getFormErrors?: boolean,
    getBackendErrors?: boolean,
    formType?: 'PRODUCT' | 'QUOTATION',
  ) {
    let message = error.code ? error.code : error.message ? error.message : error;
    let duplicate: any;
    if (getFormErrors) {
      const errorMessage = this.getFormErrors(formType);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
      if (error.detail?.inssuficientStock?.length) {
        message += ` ${error.detail?.inssuficientStock[0].name}`;
      }
      if (error.detail?.notAllowedPrice?.length) {
        message += `. Producto: ${error.detail?.notAllowedPrice[0].name}. Precio en inventario: ${error.detail?.notAllowedPrice[0].realPrice}`;
      }
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  getFormErrors(formType?: 'PRODUCT' | 'QUOTATION') {
    const error: string[] = [];

    if (formType === 'QUOTATION') {
      this.quotationForm.forEach(element => {
        const isInvalid = (this.quotationsFormId as any).getFormValidationErrorsByKey(element.key);
        if (isInvalid.length > 0) {
          error.push(element.label);
        }
      });
    } else {
      this.productForm.forEach(element => {
        const isInvalid = (this.quotationsFormId as any).getFormValidationErrorsByKey(element.key);
        if (isInvalid.length > 0) {
          error.push(element.label);
        }
      });
    }
    return error.length
      ? `${error.join(', ')}${error.indexOf(',') !== -1 ? ' son inválidos' : ' es inválido'}`
      : 'Por favor llene todos los campos';
  }

  //Delete Selected Product
  deleteProduct(productId: string) {
    this.productIdToDelete = productId;
    this.showDeletionModal = true;
  }

  cancelDeletion = () => {
    this.productIdToDelete = null;
    this.showDeletionModal = false;
  };

  confirmDeletion = () => {
    if (this.productIdToDelete && this.productIdToDelete !== '') {
      const indexToDelete = this.productsSelected.findIndex(e => e.productId === this.productIdToDelete);
      this.productsSelected.splice(indexToDelete, 1);
      this.checkDuplicateSuccessMessage('', 'Producto eliminado');
      this.fillProductsTable();
      this.showDeletionModal = false;
      this.productIdToDelete = null;
    }
  };

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  //Products Table
  async fillProductsTable() {
    if (this.productsSelected.length > 0) {
      this.showProductsTable = true;
      this.isFetchingData = true;
      this.productsTable = this.productsSelected.map(product => {
        return {
          id: product.productId,
          name: product.name,
          quantity: product.quantity,
          productPrice: product.product
            ? this.currencyPipe.transform(product.product?.price.toString(), ' ')
            : this.currencyPipe.transform(product.productPrice.toString(), ' '),
          unitPrice: this.currencyPipe.transform(product.unitPrice.toString(), ' '),
          totalPrice: this.currencyPipe.transform(product.totalPrice.toString(), ' '),
        };
      });
      this.tablePagination = {
        page: this.tablePagination.page,
        limit: this.tablePagination.limit,
        count: this.productsSelected.length,
      };
      this.isFetchingData = false;
    } else {
      this.productsTable = [];
      this.showProductsTable = false;
    }
  }

  handleChangePage(page: number) {
    this.tablePagination.page = page;
    this.fillProductsTable();
  }

  //Quotation Total Price
  calcQuotationTotal() {
    let total = 0;

    this.productsSelected.forEach(product => {
      total = total + product.totalPrice;
    });

    this.quotationTotalPrice = this.currencyPipe.transform(total.toString(), ' ') || 0;
  }

  //Product Modal
  async openProductSelectionModal(categoryId: string) {
    let noAlreadySelectedProducts: Product[] = [];
    this.productsFormId?.resetForm();

    const categoryProducts = await this.productService.getAllProducts({
      limit: 0,
      type: 'GOODS',
      categoryId: categoryId,
      sortKey: 'name',
      sortOrder: 'asc',
    });

    if (this.productsSelected.length > 0) {
      noAlreadySelectedProducts = categoryProducts.data.filter(product => {
        const alreadySelected = this.productsSelected.find(e => e.productId === product.id);
        if (alreadySelected) {
          return false;
        } else {
          return product;
        }
      });
    } else {
      noAlreadySelectedProducts = categoryProducts.data;
    }

    if (noAlreadySelectedProducts.length > 0) {
      this.productForm[0].selectOptions = noAlreadySelectedProducts.map(product => {
        return {
          label: product.name || '',
          value: product.id,
        };
      });

      this.showProductFormModal = true;
      this.productsFormId?.form.controls['productPrice'].disable();
      this.productsFormId?.form.controls['totalPrice'].disable();
      this.productsFormId?.form.controls['unitPrice'].disable();
      this.productsFormId?.form.controls['quantity'].disable();
    } else {
      this.checkDuplicateErrorMessage('', 'Ya seleccionaste todos los productos de esta categoría');
    }
  }

  onProductFormModalStatus(evt: { isOpen: boolean }) {
    this.showProductFormModal = evt.isOpen;
  }

  async addProductFormChanges(evt: object) {
    if ((evt as any).productId === null && this.productFormData.productPrice) {
      this.resetProductData();
      if (!this.productsFormId?.form.controls['unitPrice'].disabled) {
        this.productsFormId?.form.controls['unitPrice'].disable();
      }
      if (!this.productsFormId?.form.controls['quantity'].disabled) {
        this.productsFormId?.form.controls['quantity'].disable();
      }
    }

    if (!this.fetchingPrice && (evt as any).productId && (evt as any).productId !== this.productFormData.productId) {
      if ((evt as any).productId !== this.productFormData.productId) {
        this.setProductData((evt as any).productId)
          .then((productSelected: any) => {
            this.productForm[1].placeholder = productSelected.unit;
            this.productsFormId?.updateForm({
              quantity: 1,
              unitPrice: this.currencyPipe.transform((productSelected.price * 1.1).toString(), ' '),
              productPrice: productSelected.price,
            });
            this.productFormData.quantity = 1;
            this.productFormData.unitPrice = productSelected.price;
            this.productFormData.productPrice = productSelected.price * 1.1;
            this.productFormData.name = productSelected.name;

            if (this.productsFormId?.form.controls['unitPrice'].disabled) {
              this.productsFormId?.form.controls['unitPrice'].enable();
            }
            if (this.productsFormId?.form.controls['quantity'].disabled) {
              this.productsFormId?.form.controls['quantity'].enable();
            }
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            this.fetchingPrice = false;
          });
      }
    }

    if ((evt as any).quantity && (evt as any).productId) {
      if (
        (this.productFormData.quantity !== (evt as any).quantity ||
          this.productFormData.productId !== (evt as any).productId ||
          !this.productFormData.totalPrice ||
          this.productFormData.unitPrice !== (evt as any).unitPrice) &&
        !this.settingTotalPrice
      ) {
        this.setTotalPrice((evt as any).quantity, (evt as any).unitPrice);
        Object.assign(this.productFormData, { ...(evt as QuotationProduct) });
      }
    }
  }

  addProductFormISValid(evt: boolean) {
    this.isProductFormValid = evt;
  }

  cancelProductAddition = () => {
    this.showProductFormModal = false;
    this.productForm[1].placeholder = '';
    this.productsFormId?.resetForm();
  };

  addProduct = () => {
    setTimeout(() => {
      // wait for form changes
      if (this.isProductFormValid) {
        const productData = JSON.parse(JSON.stringify(this.productFormData));
        this.productsSelected.push(productData);
        this.showProductFormModal = false;
        this.resetProductData();
        this.fillProductsTable();
        this.calcQuotationTotal();
      } else {
        this.checkDuplicateErrorMessage('Formulario inválido', '', true, false, 'PRODUCT');
      }
    }, 200);
  };

  resetProductData() {
    this.productForm[1].placeholder = '';
    this.productsFormId?.resetForm();
    this.productFormData = {
      productId: '',
      name: '',
      quantity: 0,
      productPrice: 0,
      unitPrice: 0,
      totalPrice: 0,
    };
  }

  editProduct(product: any) {
    this.isProductFormLoaded = false;
    this.showProductFormModal = true;
    this.productValues = product;
    this.productValues.productId = product.id;

    setTimeout(() => {
      this.fillProductForm();
      this.isProductFormLoaded = true;
    });
  }

  async fillProductForm() {
    this.productsFormId?.updateForm({
      productId: this.productValues.productId,
      quantity: this.productValues.quantity,
      productPrice: this.productValues.product?.price,
      unitPrice: this.productValues.unitPrice,
      totalPrice: this.productValues.totalPrice,
    });
  }

  async setProductData(productId: string) {
    this.fetchingPrice = true;

    const promise = new Promise(async (res, rej) => {
      let productSelected: any;
      try {
        productSelected = await this.productService.getProductById(productId);
        res(productSelected);
      } catch (error) {
        console.error(error);
        rej(error);
      }
    });

    return promise;
  }

  setTotalPrice(quantity: any, unitPrice: number) {
    this.settingTotalPrice = true;
    const totalPrice = this.checkVariableType(quantity) * (unitPrice ? unitPrice : this.productFormData.unitPrice);
    const totalPriceFormatted = this.currencyPipe.transform(totalPrice.toString(), ' ');

    this.productsFormId?.updateForm({
      totalPrice: totalPriceFormatted,
    });

    this.productFormData.totalPrice = totalPrice;

    this.settingTotalPrice = false;
  }

  checkVariableType(value: string | number) {
    return typeof value === 'string' ? Number(value) : value;
  }

  addClient = () => {
    if (this.productsSelected.length > 0) {
      this.quotationFormData.products = this.productsSelected;
    }

    localStorage.setItem('quotationData', JSON.stringify(this.quotationFormData));
    this.router.navigate(['/private/clients/new']);
  };
}
