<div class="create__content-wrapper">
  <div class="create__header-wrapper">
    <div class="header-wrapper center-align">
      <h1 class="text-primary" *ngIf="userCanUpdate || !quotationId">
        {{ !quotationId ? 'Agregar Nueva Cotización' : 'Editar Cotización #' + quotationValues.number }}
      </h1>
      <h1 class="text-primary" *ngIf="!userCanUpdate && quotationId">
        {{ 'Detalle de la cotización' + quotationValues.number }}
      </h1>
    </div>
    <h1 *ngIf="quotationTotalPrice !== 0">{{ 'Total: $' + quotationTotalPrice }}</h1>
  </div>
  <div class="create__body-wrapper create__body-wrapper--block">
    <h3>Datos Generales</h3>
    <div class="create__form-wrapper">
      <div class="create__form-box">
        <prt-form
          #formTemplate
          [fields]="quotationForm"
          (change)="createQuotationFormChanges($event)"
          (isFormValid)="createQuotationFormISValid($event)"
        ></prt-form>
      </div>
    </div>

    <h3>Categorías de productos</h3>
    <div class="horizontal-list__wrapper" *ngIf="productCategoriesLoaded">
      <div class="horizontal-list__inner-box">
        <div
          class="horizontal-list__option-box"
          *ngFor="let productCategory of productCategories"
          (click)="openProductSelectionModal(productCategory.id)"
        >
          <img [src]="productCategory.picUrl ? productCategory.picUrl : '../../../assets/icons/products-icon.svg'" />
          <h5>{{ productCategory.name }}</h5>
        </div>
      </div>
    </div>

    <h3 style="margin-bottom: 0px">Productos Agregados</h3>
    <div class="table-external-wrapper table-external-wrapper--no-overflow-y" *ngIf="showProductsTable">
      <prt-table
        [rows]="productsTable"
        [columns]="tableColumns"
        [icons]="tableIcons"
        [actions]="tableActions"
        [pagination]="tablePagination"
        (changeCurrentPage)="handleChangePage($event)"
        [resultsLabel]="'Productos agregados'"
        [isLoading]="!isFetchingData"
        [showLoading]="true"
      >
      </prt-table>
    </div>
  </div>
  <div class="create__footer-wrapper" *ngIf="userCanUpdate || !quotationId">
    <prt-button type="outline" [callback]="cancelCreation"> Cancelar </prt-button>
    <prt-button type="solid" [callback]="createQuotation"> Calcular </prt-button>
  </div>
  <div class="create__footer-wrapper" *ngIf="!userCanUpdate && quotationId">
    <prt-button type="solid" [callback]="cancelCreation"> Aceptar </prt-button>
  </div>

  <!-- Product Form Modal -->
  <prt-modal
    [displayButton]="false"
    size="md"
    [isActive]="showProductFormModal"
    (onAction)="onProductFormModalStatus($event)"
    [hasCloseButton]="false"
  >
    <div class="modal-wrapper">
      <h2>Agregar Producto</h2>
      <div class="create__form-wrapper">
        <div class="create__form-box">
          <prt-form
            #productFormTemplate
            [fields]="productForm"
            (change)="addProductFormChanges($event)"
            (isFormValid)="addProductFormISValid($event)"
          ></prt-form>
        </div>
      </div>
      <div class="modal-buttons-wrapper">
        <prt-button type="outline" [callback]="cancelProductAddition"> Cancelar </prt-button>
        <prt-button type="solid" [callback]="addProduct"> Aceptar </prt-button>
      </div>
    </div>
  </prt-modal>

  <!-- Product Deletion Modal -->
  <prt-modal
    [displayButton]="false"
    size="sm"
    [isActive]="showDeletionModal"
    (onAction)="onDeleteModalStatus($event)"
    [hasCloseButton]="false"
  >
    <div class="modal-wrapper">
      <h2 style="text-align: center" class="text-primary">Confirmación</h2>
      <span class="paragraph-1">¿Estás seguro que deseas eliminar este producto?</span>
      <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
        <prt-button type="outline" [callback]="cancelDeletion"> Cancelar </prt-button>
        <prt-button type="solid" [callback]="confirmDeletion"> Eliminar </prt-button>
      </div>
    </div>
  </prt-modal>

  <!-- Share Quotation Modal -->
  <!-- <prt-modal [displayButton]="false" size="sm" [isActive]="showShareModal" (onAction)="onShareModalStatus($event)"
        [hasCloseButton]="false">
        <div class="modal-wrapper">
            <h2 style="text-align: center" class="text-primary"> Compartir </h2>
            <span class="paragraph-1">¿Quieres compartir esta cotización?</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button type="outline" [callback]="cancelSharing">
                    Cancelar
                </prt-button>
                <prt-button type="solid" [callback]="confirmSharing">
                    Compartir
                </prt-button>
            </div>
        </div>
    </prt-modal> -->
</div>
