<prt-layout [sideNavData]="layoutSideNavData" [mobileFrameLess]="true" *ngIf="!isPhone || !isTechnician">
    <router-outlet></router-outlet>
</prt-layout>

<main class="layout__main" *ngIf="isPhone && isTechnician">
    <div class="layout__content-wrapper">
        <div [ngClass]="{'no-padding': checkIsQuotationSend()}" class="layout__content-box">
            <div class="layout__body-wrapper">
                <router-outlet></router-outlet>
            </div>
            <div class="nav-bar-wrapper" *ngIf="navBarOptionsLoaded">
                <div class="nav-bar__option-wrapper" *ngFor="let option of navBarOptions; let i = index"
                    (click)="selectOption(i, option.route.path, option.route.params)"
                    [ngClass]="{'active': activeNavBarOption === i}">
                    <img [src]="option.img" [alt]="option.label">
                    <h5>{{option.label}}</h5>
                </div>
            </div>
        </div>
    </div>
</main>