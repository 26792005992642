import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ability } from '@casl/ability';
import { An507FormRefInterface, BasicFormBase, DropdownField, TextBoxField } from '@protostech/protos-lib';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { Role, RoleForm, User, UserForm } from 'src/app/models/auth';
import { getError } from 'src/app/models/error';
import { RolesService } from 'src/app/services/roles/roles.service';
import validation from 'src/app/utils/validation';

@Component({
  selector: 'app-roles-create',
  templateUrl: './roles-create.component.html',
  styleUrls: ['./roles-create.component.scss'],
})
export class RolesCreateComponent implements OnInit {
  @ViewChild('formTemplate', { static: false }) rolesFormId: An507FormRefInterface | undefined;

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  rolesForm: TextBoxField[] | DropdownField[] = [
    {
      controlType: 'textBox',
      key: 'name',
      type: 'text',
      value: '',
      label: 'Nombre',
      required: true,
      width: 'lg',
      minLength: 5,
      validationErrorMessage: 'Nombre inválido, debe tener mínimo 5 caracteres',
      showErrorsUx: true,
      noMargin: true,
    },
    {
      controlType: 'textBox',
      key: 'description',
      type: 'string',
      value: '',
      label: 'Descripción',
      required: true,
      width: 'lg',
      validationErrorMessage: 'Descripción inválida',
      showErrorsUx: true,
      noMargin: true,
    },
  ];

  isRolesFormValid = false;
  isFetchingData = true;

  rolesFormData: RoleForm = {
    name: '',
    description: '',
    permissions: [],
  };

  isRolesFormLoaded = false;

  roleId: string | null = null;

  roleValues: Role = {
    name: '',
    id: '',
    description: '',
    permissions: [],
  };

  translations: Record<string, string> = {
    manage: 'Todos',
    create: 'Crear',
    update: 'Modificar',
    read: 'Ver',
    delete: 'Eliminar',
    subject: 'Funcionalidad',
    subjects: 'Funcionalidades',
    default: 'Desconocido',
    Role: 'Rol',
    User: 'Usuario',
    Client: 'Cliente',
    Product: 'Producto',
    Quotation: 'Cotización',
    Technician: 'Técnico',
  };

  actionsColumns: TableColumn[] = [];
  subjectsRows: Record<string, string | boolean>[] = [];
  actions: string[] = [];
  subjects: string[] = [];

  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  permissionsTablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private rolesService: RolesService,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    this.isRolesFormLoaded = false;
    this.getPermissions();

    this.route.queryParamMap.subscribe(async params => {
      this.roleId = params.get('roleId');
      if (this.roleId) {
        this.roleValues = await this.rolesService.getRolById(this.roleId);

        setTimeout(() => {
          this.fillForm();
          // this.isRolesFormLoaded = true;
          this.canUpdate();
        });
      } else {
      }
    });
  }

  async fillForm() {
    if (this.roleId) {
      this.roleValues = await this.rolesService.getRolById(this.roleId);
      this.rolesFormId?.updateForm({
        name: this.roleValues.name,
        description: this.roleValues.description,
        permissions: this.roleValues.permissions,
      });
      const rows = this.subjectsRows;
      rows.forEach((cur, index) => {
        const permsToCheck = this.roleValues.permissions.filter(perm => cur['subjectProp'] === perm.subject);
        if (permsToCheck) {
          permsToCheck.forEach(innerPerm => {
            if (innerPerm.action === 'create') {
              this.subjectsRows[index]['checkbox1'] = true;
            }
            if (innerPerm.action === 'read') {
              this.subjectsRows[index]['checkbox2'] = true;
            }
            if (innerPerm.action === 'update') {
              this.subjectsRows[index]['checkbox3'] = true;
            }
            if (innerPerm.action === 'delete') {
              this.subjectsRows[index]['checkbox4'] = true;
            }
          });
        }
      });
    }
  }

  getPermissions(): Promise<void | string[][]> {
    return Promise.all([this.rolesService.getAllActions(), this.rolesService.getAllSubjects()]).then(res => {
      this.actions = res[0];
      this.subjects = res[1];

      this.buildTableData();
      this.isRolesFormLoaded = true;
    });
  }

  //Permissions

  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Role');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Role')) {
      this.rolesFormId?.form.disable();
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  checkRoleReadOnlyPermission() {
    return (
      this.ability.can('read', 'Role') && !this.ability.can('update', 'Role') && !this.ability.can('create', 'Role')
    );
  }

  //Create Role
  createRole = () => {
    this.rolesFormId?.updateForm({
      permissions: this.buildPermissionsForForm(),
    });
    this.rolesFormData.permissions = this.buildPermissionsForForm();
    if (this.roleValues?.id && this.isRolesFormValid) {
      const formData = {
        name: this.rolesFormData.name,
        description: this.rolesFormData.description,
        permissions: this.rolesFormData.permissions,
        id: this.roleValues.id,
      };
      this.rolesService
        .updateRol(formData)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Rol editado');
          this.router.navigate(['/private/admin/roles']);
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    } else if (!this.roleId && this.isRolesFormValid) {
      this.rolesService
        .createRol(this.rolesFormData)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Rol creado');
          this.router.navigate(['/private/admin/roles']);
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    } else {
      this.checkDuplicateErrorMessage('Formulario inválido', '', true, false);
    }
  };

  createFormChanges(evt: object) {
    if (!(evt instanceof Event)) {
      Object.assign(this.rolesFormData, { ...(evt as RoleForm) });
    }
  }

  createFormISValid(evt: boolean) {
    this.isRolesFormValid = evt;
  }

  cancelCreation = () => {
    this.router.navigate(['/private/admin/roles']);
  };

  //Roles Table
  buildTableData(): void {
    this.actionsColumns = this.actions
      .filter(cur => cur !== 'manage')
      .map((cur, index) => ({
        name: this.translations[cur],
        prop: `checkbox${index + 1}`,
        sortable: false,
      }));
    const checkboxes: Record<string, boolean>[] = this.actions.map((cur, index) => ({
      [`checkbox${index + 1}`]: false,
    }));
    if (this.translations['subject'] === 'Funcionalidad') {
      this.actionsColumns.unshift({
        name: this.translations['subject'],
        prop: 'subject',
        maxWidth: 3000,
        sortable: false,
      });
    } else {
      this.actionsColumns.unshift({ name: this.translations['subject'], prop: 'subject' });
    }
    this.actionsColumns.unshift({ name: 'Todos', prop: 'selectAll', sortable: false });
    const checkboxesT = checkboxes.reduce((accum, val) => {
      Object.assign(accum, val);
      return accum;
    }, {});
    this.subjectsRows = this.subjects.map(cur => {
      const subject = { subject: this.translations[cur] ?? cur };
      Object.assign(subject, checkboxesT);
      Object.assign(subject, { subjectProp: cur });
      return subject;
    });
  }

  buildPermissionsForForm = () => {
    const permissions: { action: string; subject: string }[] = [];
    this.subjectsRows.forEach(cur => {
      // if (cur['checkbox1']) {
      //   permissions.push({ subject: cur['subject'] as string, action: 'manage' });
      // }
      if (cur['checkbox1']) {
        permissions.push({ subject: cur['subjectProp'] as string, action: 'create' });
      }
      if (cur['checkbox2']) {
        permissions.push({ subject: cur['subjectProp'] as string, action: 'read' });
      }
      if (cur['checkbox3']) {
        permissions.push({ subject: cur['subjectProp'] as string, action: 'update' });
      }
      if (cur['checkbox4']) {
        permissions.push({ subject: cur['subjectProp'] as string, action: 'delete' });
      }
    });
    return permissions;
  };

  handleChangePermissionPage(page: number) {
    this.permissionsTablePagination.page = page;
  }

  //Error Messages
  checkDuplicateErrorMessage(title: string, message: string, getFormErrors?: boolean, getBackendErrors?: boolean) {
    let duplicate: any;
    if (getFormErrors) {
      const errorMessage = this.getFormErrors();
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  getFormErrors() {
    const error: string[] = [];
    this.rolesForm.forEach(element => {
      const isInvalid = (this.rolesFormId as any).getFormValidationErrorsByKey(element.key);
      if (isInvalid.length > 0) {
        error.push(element.label);
      }
    });
    return `${error.join(', ')}${error.indexOf(',') !== -1 ? ' son inválidos' : ' es inválido'}`;
  }
}
