<!-- Roles -->
<div class="content-wrapper">
    <div class="header-wrapper">
        <h1 class="text-primary"> Lista de Roles</h1>
        <prt-button *ngIf="userCanCreate" color="secondary" [italic]="true" [callback]="addRol">
            <div class="button-box">
                <img class="button-icon" src="../../../assets/icons/add-icon.svg" alt="attach">
                <h5 class="add-label">Agregar</h5>
            </div>
        </prt-button>
    </div>
    <div class="search-wrapper">
      <div class="search-form-wrapper">
        <prt-form #searchFormTemplate [fields]="searchForm" (change)="searchFormChanges($event)"></prt-form>
      </div>
    </div>
    <div class="table-external-wrapper">
        <prt-table [rows]="rolesTable" [columns]="tableColumns" [icons]="tableIcons" [actions]="tableActions"
            [pagination]="tablePagination" [resultsLabel]="'roles'" [isLoading]="!isFetchingData" [showLoading]=true
            (changeCurrentPage)="handleChangePage($event)"
        [isExporting]="isExporting"
        (onSorting)="onSortTable($event)"
        columnMode="force"></prt-table>
    </div>

    <prt-modal [displayButton]="false" size="sm" [isActive]="showDeletionModal"
        (onAction)="onDeleteModalStatus($event)" [hasCloseButton]="false">
        <div class="modal-wrapper">
            <h2 style="text-align: center" class="text-primary"> Confirmación </h2>
            <span class="paragraph-1">¿Estás seguro que deseas eliminar este rol?</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button color="secondary" [italic]="true" [callback]="cancelRolDeletion">
                    Cancelar
                </prt-button>
                <prt-button color="primary" [callback]="confirmDeletion">
                    Eliminar
                </prt-button>
            </div>
        </div>
    </prt-modal>
</div>
