import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CreatedPdfService {
  createdReportUser(users: any[]) {
    const bodyData: string[][] = [];
    users.forEach((user: any) => {
      const dataRow: string[] = [];
      dataRow.push(user.name);
      dataRow.push(user.email);
      dataRow.push(user.rol);
      dataRow.push(user.lastLogin);
      bodyData.push(dataRow);
    });
    const pdfDefinition: any = {
      content: [
        {
          text: 'Dorita',
          style: 'header',
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },
        { text: 'Administradores:', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [
              [
                { text: 'Nombre y Apellido', style: 'tableHeader' },
                { text: 'Email', style: 'tableHeader' },
                { text: 'Rol ', style: 'tableHeader' },
                { text: 'Última conexión ', style: 'tableHeader' },
              ],
              ...bodyData,
            ],
          },
          layout: 'headerLineOnly',
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'justify',
        },
        subheader: {
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
      },
    };
    return pdfDefinition;
  }

  createdReportRole(roles: any[]) {
    const bodyData: string[][] = [];

    roles.forEach((role: any) => {
      const dataRow: string[] = [];
      dataRow.push(role.name);
      dataRow.push(role.description);
      bodyData.push(dataRow);
    });
    const pdfDefinition: any = {
      content: [
        {
          text: 'Dorita',
          style: 'header',
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },
        { text: 'Roles:', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [
              [
                { text: 'Nombre', style: 'tableHeader' },
                { text: 'Descripción ', style: 'tableHeader' },
              ],
              ...bodyData,
            ],
          },
          layout: 'headerLineOnly',
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'justify',
        },
        subheader: {
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
      },
    };
    return pdfDefinition;
  }

  createdReportTechnician(technicians: any[]) {
    const bodyData: string[][] = [];
    technicians.forEach((technician: any) => {
      const dataRow: string[] = [];
      dataRow.push(technician.name);
      dataRow.push(technician.code);
      dataRow.push(technician.quotationsCount);
      dataRow.push(technician.salesCount);
      bodyData.push(dataRow);
    });
    const pdfDefinition: any = {
      content: [
        {
          text: 'Dorita',
          style: 'header',
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },
        { text: 'Técnicos:', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [
              [
                { text: 'Nombre y Apellido', style: 'tableHeader' },
                { text: 'Código', style: 'tableHeader' },
                { text: 'Cotizaciones', style: 'tableHeader' },
                { text: 'Ventas', style: 'tableHeader' },
              ],
              ...bodyData,
            ],
          },
          layout: 'headerLineOnly',
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'justify',
        },
        subheader: {
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
      },
    };
    return pdfDefinition;
  }

  createdReportClients(clients: any[]) {
    const bodyData: string[][] = [];
    clients.forEach((client: any) => {
      const dataRow: string[] = [];
      dataRow.push(client.number);
      dataRow.push(client.name);
      dataRow.push(client.phone);
      dataRow.push(client.address);
      dataRow.push(client.purchasesCount);
      dataRow.push(client.User);
      bodyData.push(dataRow);
    });
    const pdfDefinition: any = {
      content: [
        {
          text: 'Dorita',
          style: 'header',
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },
        { text: 'Clientes:', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [
              [
                { text: 'Cliente', style: 'tableHeader' },
                { text: 'Nombre y Apellido', style: 'tableHeader' },
                { text: 'Teléfono', style: 'tableHeader' },
                { text: 'Dirección', style: 'tableHeader' },
                { text: 'Compras', style: 'tableHeader' },
                { text: 'Técnico', style: 'tableHeader' },
              ],
              ...bodyData,
            ],
          },
          layout: 'headerLineOnly',
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'justify',
        },
        subheader: {
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
      },
    };
    return pdfDefinition;
  }

  createdReportProducts(products: any[]) {
    const bodyData: string[][] = [];
    products.forEach((product: any) => {
      const dataRow: string[] = [];
      dataRow.push(product.name);
      dataRow.push(product.Category);
      bodyData.push(dataRow);
    });
    const pdfDefinition: any = {
      content: [
        {
          text: 'Dorita',
          style: 'header',
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },
        { text: 'Productos:', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [
              [
                { text: 'Producto', style: 'tableHeader' },
                { text: 'Categoría', style: 'tableHeader' },
              ],
              ...bodyData,
            ],
          },
          layout: 'headerLineOnly',
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'justify',
        },
        subheader: {
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
      },
    };
    return pdfDefinition;
  }

  createdReportCategories(categories: any[]) {
    const bodyData: string[][] = [];
    categories.forEach((category: any) => {
      const dataRow: string[] = [];
      dataRow.push(category.name);
      bodyData.push(dataRow);
    });
    const pdfDefinition: any = {
      content: [
        {
          text: 'Dorita',
          style: 'header',
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },
        { text: 'Categorías:', fontSize: 14, bold: true, margin: [0, 20, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [[{ text: 'Categoría', style: 'tableHeader' }], ...bodyData],
          },
          layout: 'headerLineOnly',
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'justify',
        },
        subheader: {
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
      },
    };
    return pdfDefinition;
  }

  createdReportQuotation(quotations: any[], isTechnicianReport?: boolean) {
    const bodyData: string[][] = [];
    quotations.forEach((quotation: any) => {
      const dataRow: string[] = [];
      dataRow.push(quotation.status);
      dataRow.push(quotation.total);
      dataRow.push(quotation.number);
      dataRow.push(quotation.createdAt);
      dataRow.push(quotation.Client);
      dataRow.push(quotation.User);
      dataRow.push(quotation.profits);
      dataRow.push(quotation.note);
      bodyData.push(dataRow);
    });
    const pdfDefinition: any = {
      content: [
        {
          text: 'Dorita',
          style: 'header',
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },
        { text: 'Cotizaciones:', fontSize: 14, bold: true, margin: [0, 10, 0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [
              [
                { text: 'Estatus', style: 'tableHeader' },
                { text: 'Monto', style: 'tableHeader' },
                { text: 'Número', style: 'tableHeader' },
                { text: 'Fecha', style: 'tableHeader' },
                { text: 'Cliente', style: 'tableHeader' },
                { text: 'Técnico', style: 'tableHeader' },
                { text: 'Ganancias', style: 'tableHeader' },
                { text: 'Detalles', style: 'tableHeader' },
              ],
              ...bodyData,
            ],
          },
          layout: 'headerLineOnly',
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'justify',
        },
        subheader: {
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
        tableHeader: {
          fontSize: 10,
        },
        tableExample: {
          fontSize: 8,
        },
      },
    };
    return pdfDefinition;
  }
}
