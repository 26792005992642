import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ability } from '@casl/ability';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/models/clients';
import { getError } from 'src/app/models/error';
import { Quotation } from 'src/app/models/quotation';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';

@Component({
  selector: 'app-quotations-send',
  templateUrl: './quotations-send.component.html',
  styleUrls: ['./quotations-send.component.scss'],
})
export class QuotationsSendComponent implements OnInit {
  userCanUpdate = false;
  showClientData = false;

  switch = false;

  isCreatedStatus = true;
  isSentStatus = true;

  quotationId: string | null = null;
  clientId: string | null = null;

  quotationValues: Quotation = {
    createdAt: new Date(),
    id: '',
    note: '',
    clientId: '',
    userId: '',
    products: [
      {
        detail: '',
        name: '',
        productId: '',
        quantity: 0,
        totalPrice: 0,
        unitPrice: 0,
      },
    ],
    updatedAt: new Date(),
    Client: {
      name: '',
      number: 0,
    },
    User: {
      name: '',
    },
    number: 0,
    numberStr: '',
    profits: 0,
    saleOrderId: '',
    status: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    showDetail: false,
  };

  clientValues: Partial<Client> = {
    createdAt: new Date(),
    email: '',
    name: '',
    id: '',
    address: '',
    dni: '',
    phone: '',
    phoneCode: '',
    updatedAt: new Date(),
    purchasesCount: '',
    userId: '',
    User: {
      name: '',
    },
    number: 0,
    numberStr: '',
    syncId: '',
    zohoId: '',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private quotationService: QuotationsService,
    private clientService: ClientsService,
  ) {
    //
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async params => {
      this.quotationId = params.get('quotationId');
      if (this.quotationId) {
        this.quotationValues = await this.quotationService.getQuotationById(this.quotationId);

        this.clientId = this.quotationValues.clientId;
        this.clientValues = await this.clientService.getClientById(this.clientId);

        if (this.quotationValues.status !== 'CREATED') {
          this.isCreatedStatus = false;
        }
        if (this.quotationValues.status !== 'SENT') {
          this.isSentStatus = false;
        }
      }
    });
  }

  //Permissions
  canUpdate() {
    this.userCanUpdate = this.ability.can('update', 'Quotation');
  }

  //Share quotation
  cancelSharing = () => {
    this.router.navigate(['/private/quotations']);
  };

  confirmSharing = () => {
    if (this.isCreatedStatus) {
      this.quotationService
        .createQuotationToShare(this.quotationId ? this.quotationId : '')
        .then((res: any) => {
          if (navigator.share) {
            navigator
              .share({
                title: 'Compartir Cotización',
                text: 'Cotización #' + this.quotationId,
                url: res.url,
              })
              .then(() => {
                this.checkDuplicateSuccessMessage('', 'Cotización enviada exitosamente');
                this.router.navigate(['/private/quotations']);
              })
              .catch(error => {
                console.error(error);
                this.router.navigate(['/private/quotations']);
              });
          }
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error, true);
            if (err.error.code == 'inssuficient_stock') {
              this.router.navigate(['/private/quotations/edit'], { queryParams: { quotationId: this.quotationId } });
            }
          } else {
            this.checkDuplicateErrorMessage('Error', err.error);
          }
        });
    } else if (this.isSentStatus) {
      if (navigator.share) {
        navigator
          .share({
            title: 'Compartir Cotización',
            text: 'Cotización #' + this.quotationId,
            url: `https://${window.location.hostname}/public/quotations/${this.quotationId}`,
          })
          .then(() => {
            this.router.navigate(['/private/quotations']);
          })
          .catch(error => {
            console.error(error);
            this.router.navigate(['/private/quotations']);
          });
      }
    } else {
      window.open(`https://${window.location.hostname}/public/quotations/${this.quotationId}`, '_blank');
    }
  };

  //Edit Quotation
  editQuotation() {
    this.router.navigate(['/private/quotations/edit'], { queryParams: { quotationId: this.quotationId } });
  }

  //Error Messages
  checkDuplicateErrorMessage(title: string, error: any, getBackendErrors?: boolean) {
    let message = error.code ? error.code : error.message;
    let duplicate: any;
    if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
      if (error.detail?.inssuficientStock?.length) {
        message += ` ${error.detail?.inssuficientStock[0].name}`;
      }
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }
}
