import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Ability } from '@casl/ability';
import { ToastrService } from 'ngx-toastr';
import { getError } from 'src/app/models/error';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';

@Component({
  selector: 'app-check-payment',
  templateUrl: './check-payment.component.html',
  styleUrls: ['./check-payment.component.scss'],
})
export class CheckPaymentComponent implements OnInit {
  quotationId: string | null = null;
  paymentData: any = {};
  showSuccess = false;
  showError = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private quotationService: QuotationsService,
    private clientService: ClientsService,
  ) {
    //
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async params => {
      this.quotationId = params.get('quotationId');
      if (this.quotationId && this.quotationId !== '') {
        // EMETEC
        // this.quotationService
        //   .checkQuotationPayment(this.quotationId)
        //   .then(res => {
        //     this.paymentData = res;
        //     this.checkDuplicateSuccessMessage('', 'Cotización pagada exitosamente');
        //     this.showSuccess = true;
        //   })
        //   .catch(err => {
        //     console.error(err);
        //     this.showError = true;
        //     if (err.error.code) {
        //       this.checkDuplicateErrorMessage('Error', err.error.code);
        //     } else {
        //       this.checkDuplicateErrorMessage('Error', err.error.message);
        //     }
        //   });

        // PAGUELOFACIL
        const codOper = params.get('Oper');
        if (codOper) {
          this.quotationService
            .checkQuotationPayment(this.quotationId, codOper)
            .then(res => {
              this.paymentData = res;
              this.checkDuplicateSuccessMessage('', 'Cotización pagada exitosamente');
              this.showSuccess = true;
            })
            .catch(err => {
              console.error(err);
              this.showError = true;
              if (err.error.code) {
                this.checkDuplicateErrorMessage('Error', err.error.code);
              } else {
                this.checkDuplicateErrorMessage('Error', err.error.message);
              }
            });
        } else {
          this.showError = true;
        }
      }
    });
  }

  //Error Messages
  checkDuplicateErrorMessage(title: string, message: string, getBackendErrors?: boolean) {
    let duplicate: any;
    if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  goToQuotationResume = () => {
    this.router.navigate(['/public/quotations/' + this.quotationId]);
  };
}
