import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { An507FormRefInterface, BasicFormBase, DropdownField, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import validation from 'src/app/utils/validation';
import { Ability } from '@casl/ability';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';
import moment from 'moment';
import { Quotation } from 'src/app/models/quotation';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss'],
})
export class QuotationsComponent implements OnInit {
  @ViewChild('searchFormTemplate', { static: false }) searchFormId: An507FormRefInterface | undefined;

  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];
  searchQuery: any = {
    page: 1,
    limit: 10,
    sortKey: 'createdAt',
    sortOrder: 'desc',
  };

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  tableActions: Record<string, unknown> = {
    modifyAction: (row: Record<string, unknown>) => this.editQuotation(row['id'] as string, row['status'] as string),
    deleteAction: (row: Record<string, unknown>) => this.deleteQuotation(row['id'] as string, row['status'] as string),
  };

  quotationsTable: any[] = [];

  tableColumns = [
    {
      prop: 'status',
      name: 'Estatus',
    },
    {
      prop: 'total',
      name: 'Monto',
      columnAlign: 'center',
    },
    {
      prop: 'number',
      name: 'Número',
      columnAlign: 'center',
    },
    {
      prop: 'createdAt',
      name: 'Fecha',
    },
    {
      prop: 'Client',
      name: 'Cliente',
      columnAlign: 'center',
    },
    {
      prop: 'User',
      name: 'Técnico',
    },
    {
      prop: 'profits',
      name: 'Ganancias',
      columnAlign: 'center',
    },
    {
      prop: 'note',
      name: 'Detalles',
      sortable: true,
    },
  ];

  tablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  quotationId: string | null = null;

  showDeletionModal = false;
  isFetchingData = true;
  isExporting = false;

  statusTranslations: Record<string, string> = {
    CREATED: 'Borrador',
    SENT: 'Enviada',
    ACCEPTED: 'Aceptada',
    PAID: 'Pagada',
    CANCELED: 'Cancelada',
  };

  canBeDeleted = false;

  technicianId: string | null = null;
  total = 0;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ability: Ability,
    private pdfSvc: CreatedPdfService,
    private quotationService: QuotationsService,
    private authService: AuthService,
    private technicianService: TechniciansService,
  ) {
    if (ability.can('delete', 'Quotation')) {
      this.tableColumns.push({
        prop: 'delete',
        name: '',
        sortable: false,
      });
    }
  }

  async ngOnInit() {
    try {
      const me = await firstValueFrom(this.authService.getMe());
      if (this.technicianService.isTechnician(me.role.name)) {
        //TO DO Verificar cuando es tecnico y cuando no
        this.technicianId = me.id;
        if (this.technicianId) {
          this.searchQuery = { ...this.searchQuery, userId: this.technicianId };
        }
      }

      this.route.queryParamMap.subscribe(async params => {
        let sortBy = params.get('sortBy');
        const sortOrder = params.get('sortOrder');
        const page = Number(params.get('page'));

        if (sortBy === 'User') {
          sortBy = sortBy + '.name';
        } else if (sortBy === 'Client') {
          sortBy = sortBy + '.number';
        }
        this.buildQuery(sortBy, sortOrder, page);
        if (page) {
          this.tablePagination['page'] = page;
        }
        this.fillTable(sortBy, sortOrder);
      });

      this.canCreate();
      this.tableActions['downloadAction'] = this.handleCreateReport;
    } catch (error) {
      console.error(error);
      this.router.navigate(['/login']);
    }
  }

  ngAfterViewInit() {
    this.searchForm[0].triggerOnEnter = this.searchOnEnter;
    this.searchForm[0].triggerOnBlur = this.searchOnBlur;
  }

  //Search
  buildQuery(sortKey?: string | null, sortOrder?: string | null, page?: number) {
    if (page) {
      Object.assign(this.searchQuery, page);
    }
    if (sortKey) {
      Object.assign(this.searchQuery, { sortKey, sortOrder });
    }
  }

  searchOnEnter = (evt: any) => {
    if (evt.key === 'Enter') {
      const value = evt.target.value;
      this.updateSearchForm(value);
    }
  };

  searchOnBlur = (evt: any) => {
    this.updateSearchForm(evt.target.value);
  };

  updateSearchForm = (value: string) => {
    this.searchFormId?.form.setValue({ [this.searchForm[0].key]: value }, { emitEvent: true });
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsDirty();
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsTouched();
    this.search();
  };

  search = async () => {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const quotations = await this.quotationService.getAllQuotations(this.searchQuery);
        this.total = quotations.total || 0;

        this.quotationsTable = quotations.data.map(quotation => {
          return {
            id: quotation.id,
            status: this.translateStatus(quotation.status),
            total: `$${quotation.total}`,
            number: quotation.numberStr,
            createdAt: moment.utc(quotation.createdAt).format('DD/MM/yyyy'),
            Client: quotation.Client.number,
            User: quotation.User.name,
            profits: `$${quotation.profits}`,
            note: quotation.note,
          };
        });

        this.tablePagination = { page: quotations.page, limit: quotations.limit, count: quotations.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  };

  async searchFormChanges(evt: { query: string } | unknown) {
    if ((evt as { query: string }).query) {
      this.searchQuery = {
        ...this.searchQuery,
        ['q']: (evt as { query: string }).query,
      };
    } else {
      this.resetSearchQuery();
    }
  }

  resetSearchQuery() {
    delete this.searchQuery['q'];
    this.searchQuery = {
      ...this.searchQuery,
      page: 1,
      limit: 10,
    };
    if (this.technicianId) {
      Object.assign(this.searchQuery, { userId: this.technicianId });
    }
  }

  //CRUD
  editQuotation(quotationId: string, status: string) {
    if (status == 'Creada') {
      this.router.navigate(['/private/quotations/edit'], { queryParams: { quotationId: quotationId } });
    } else if (status == 'Enviada') {
      this.router.navigate(['/private/quotations/send'], { queryParams: { quotationId: quotationId } });
    } else {
      this.router.navigate(['/public/quotations/' + quotationId]);
    }
  }

  addQuotation = () => {
    this.router.navigate(['/private/quotations/new']);
  };

  deleteQuotation(quotationId: string, status: string) {
    if (status === 'Creada' || status === 'Cancelada') {
      this.canBeDeleted = true;
    }
    this.quotationId = quotationId;
    this.showDeletionModal = true;
  }

  cancelDeletion = () => {
    this.quotationId = null;
    this.showDeletionModal = false;
    this.canBeDeleted = false;
  };

  confirmDeletion = () => {
    if (this.quotationId && this.quotationId !== '') {
      this.quotationService
        .deleteQuotation(this.quotationId)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Cotización eliminada');
          this.fillTable();
          this.showDeletionModal = false;
          this.quotationId = null;
          this.canBeDeleted = false;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  // Error Messages

  checkDuplicateErrorMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  //Table
  async fillTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        let quotations: any;

        if (this.technicianId) {
          quotations = await this.quotationService.getAllQuotations({
            userId: this.technicianId,
            page: this.tablePagination.page,
            limit: this.tablePagination.limit,
            sortKey: sortKey ? sortKey : 'createdAt',
            sortOrder: sortOrder ? sortOrder : 'desc',
          });
        } else {
          quotations = await this.quotationService.getAllQuotations({
            page: this.tablePagination.page,
            limit: this.tablePagination.limit,
            sortKey: sortKey ? sortKey : 'createdAt',
            sortOrder: sortOrder ? sortOrder : 'desc',
          });
        }
        this.total = quotations.total;

        this.quotationsTable = quotations.data.map((quotation: Quotation) => {
          return {
            id: quotation.id,
            status: this.translateStatus(quotation.status),
            total: `$${quotation.total}`,
            number: quotation.numberStr,
            createdAt: moment.utc(quotation.createdAt).format('DD/MM/yyyy'),
            Client: quotation.Client.number,
            User: quotation.User.name,
            profits: `$${quotation.profits}`,
            note: quotation.note,
          };
        });

        this.tablePagination = { page: quotations.page, limit: quotations.limit, count: quotations.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  }

  handleChangePage(page: number) {
    this.tablePagination.page = page;
    this.router.navigate(['/private/quotations'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  onSortTable(evt: { dir: string; column: string }) {
    this.tablePagination.page = 1;
    this.router.navigate(['/private/quotations'], {
      queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
      queryParamsHandling: 'merge',
    });
  }

  translateStatus(statusKey: string) {
    return this.statusTranslations[statusKey];
  }

  //Permission
  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Quotation');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Quotation')) {
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Report
  handleCreateReport = async () => {
    this.isExporting = true;
    this.searchQuery['limit'] = 0;
    const quotations = await this.quotationService.getAllQuotations(this.searchQuery);
    const quotationReport = quotations.data.map(quotation => {
      return {
        status: this.translateStatus(quotation.status),
        total: `$${quotation.total}`,
        number: quotation.numberStr,
        createdAt: moment.utc(quotation.createdAt).format('DD/MM/yyyy'),
        Client: quotation.Client.number,
        User: quotation.User.name,
        profits: `$${quotation.profits}`,
        note: quotation.note,
      };
    });
    const pdfDefinition: any = this.pdfSvc.createdReportQuotation(quotationReport);
    pdfMake.createPdf(pdfDefinition).download('Cotizaciones.pdf');
    this.isExporting = false;
    this.searchQuery['limit'] = 10;
  };
}
